<template>
  <div :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="main-card mb-3 card">
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header-tab card-header">
      <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-header-title card-title font-size-lg text-capitalize font-weight-normal">
        <font-awesome-icon :icon="['fal','calendar-star']" size="2x" class="pr-2 top-products"/>
        {{$t('analytic.lang_nav_analytics_mostsoldwares')}}
      </div>
    </div>
    <div class="border-light card-border scroll-area-md card transparent">

      <ul class="list-group list-group-flush" v-if="!loading" >
        <li :class="$vuetify.theme.dark? 'transparent' : ''" class="list-group-item" v-for="(item,i) in data" :key="i">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="row no-gutters align-center">
                <div class="col-4">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left mr-3">
                      <div  width="38" class="rounded-circle">
                        <font-awesome-icon v-if="!item.iconType" :icon="['fal','shopping-cart']" size="2x" />
                        <font-awesome-icon v-else :icon="['fal',item.iconType]" size="2x" />
                      </div>
                    </div>
                    <div class="widget-content-left">
                      <div class="widget-heading">{{item.itemName}}</div>
                      <div class="widget-subheading mt-1 opacity-10">
                        <div class="badge badge-pill badge-primary">{{getTotal(item) | currency}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row no-gutters justify-center">
                    <div class="widget-content-right">
                      <div class="progress-circle-wrapper">
                        <v-progress-circular
                            :value="setPercent(item.salesYetersay,item.qty)"
                            :size="52"
                            :color="(setPercent(item.salesYetersay,item.qty)>=0)?f6.gradient[0]:f5.gradient[0]" style="font-size: 10px;">
                          {{setPercent(item.salesYetersay,item.qty).toFixed(2)+'%'}}
                        </v-progress-circular>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row no-gutters justify-end">
                    <div class="widget-content-right">
                      <div class="fsize-2 text-success">
                        <span>{{Number(item.qty).toFixed(0)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="row justify-center" v-if="!(loading) && emptyData">
        <div class="col-12 text-center" ><h3>{{$t('generic.lang_nothingtoshow')}}</h3></div>
      </div>
      <div class="row justify-center">
        <div class="col-12">
          <v-skeleton-loader
              v-if="loading"
              height="94"
              type="list-item-avatar-two-line"
          >
          </v-skeleton-loader>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import {mapState} from 'vuex';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fal);

export default {
  name: "BestSellingComponent",
  components:{
    VuePerfectScrollbar,
    FontAwesomeIcon,
  },
  computed:{
    ...mapState([
      'api'
    ]),
    emptyData(){
      return !(Object.values(this.data).length>0);
    }
  },
  data(){
    return{
      loading:true,
      data:[],
      f5: {gradient: ["#d92550"],},
      f6: {gradient: ["#31a66a"],},
    }
  },
  methods:{
    async getTopItemsDaily(){
      this.loading=true;
      this.axios.post("get/dashboardOnlineShop/topTenItemsDaily/").then(res=>{
        this.loading = false;
        if(res.data.success) {
          this.data=Object.values(res.data.data);

        }else{
          ////console.log("error");
        }

      }).catch(err=>{
        //console.log("data error");
        this.loading = false;
      })

    },
    getTotal(item){
      let qty=Number(item.qty);
      let price=Number(item.totalPricePerItem);
      return (qty*price).toFixed(2);
    },
    setPercent(oldNum,newNum){

      oldNum=(isNaN(parseInt(oldNum)))?0:parseInt(oldNum);
      newNum=(isNaN(parseInt(newNum)))?0:parseInt(newNum);

      let increasePercent =0;

      let increase = newNum - oldNum;

      increasePercent = (increase / (( oldNum===0)?1:oldNum))*100;
      return increasePercent;
    }
  },
  mounted() {
    this.getTopItemsDaily();
  }
}
</script>

<style scoped>
.top-products{
  color :orange;
}
</style>
