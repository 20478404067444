<template>
  <div>
    <page-title :heading="$t('generic.lang_onlineshopDashboard')" :subheading="$t('generic.lang_onlineshopDashboard')" :icon=icon></page-title>
    <div class="app-main__inner">
      <SalesDailyOverviewComponent/>
      <BestSellingComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import SalesDailyOverviewComponent from "../../components/onlineshop/dashboard/SalesDailyOverviewComponent";
import BestSellingComponent from "../../components/onlineshop/dashboard/BestSellingComponent";

export default {
  components: {
    BestSellingComponent,
    SalesDailyOverviewComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-shopbag icon-gradient bg-tempting-azure',
  })
}
</script>