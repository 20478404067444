<template>
  <v-card :loading="loading" class="main-card mb-3 card">
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header-tab card-header">
      <div :class="[this.$vuetify.theme.dark? 'text-white' : '']" class="card-header-title card-title font-size-lg text-capitalize font-weight-normal">
        <font-awesome-icon :icon="['fal','calendar-day']" size="2x" class="pr-2 daily"/>
        {{$t('settings.lang_dailyOverviewDashboard')}}
      </div>
    </div>

    <div class="grid-menu grid-menu-2col">
      <div class="no-gutters row">
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div class="widget-chart">
            <div class="icon-wrapper rounded-circle">
              <div class="icon-wrapper-bg bg-primary"></div>
              <v-icon size="26">euro</v-icon></div>
            <div class="widget-numbers">{{data.totalNetto | currency}}</div>
            <div class="widget-subheading">{{$t('generic.lang_netSales')}}</div>
            <div :class="'widget-description '+margins.totalNetto.color">
              <font-awesome-icon :icon="margins.totalNetto.icon"/>
              <span class="pl-1">{{margins.totalNetto.increase}}%</span></div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div class="widget-chart">
            <div class="icon-wrapper rounded-circle">
              <div class="icon-wrapper-bg bg-alternate"></div>
              <font-awesome-icon :icon="['fas','coins']" size="2x" class="mx-auto"/></div>
            <div class="widget-numbers">{{data.totalBrutto | currency}}</div>
            <div class="widget-subheading">{{$t('erp.lang_grossSales')}}</div>
            <div :class="'widget-description '+margins.totalBrutto.color ">
              <font-awesome-icon :icon="margins.totalBrutto.icon"/>
              <span class="pl-1">{{margins.totalBrutto.increase}}%</span></div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div class="widget-chart">
            <div class="icon-wrapper rounded-circle">
              <div class="icon-wrapper-bg bg-warning"></div>
              <font-awesome-icon :icon="['fas','user-friends']" size="2x" class="mx-auto"/>
            </div>
            <div class="widget-numbers">{{data.invoices}}</div>
            <div class="widget-subheading">{{$t('customers.lang_customers')}}</div>
            <div :class="'widget-description '+margins.invoices.color">
              <font-awesome-icon :icon="margins.invoices.icon"/>
              <span class="pl-1">{{margins.invoices.increase}}%</span></div>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div class="widget-chart">
            <div class="icon-wrapper rounded-circle">
              <div class="icon-wrapper-bg bg-primary"></div>
              <font-awesome-icon :icon="['fal','tachometer-alt-average']" size="2x" class="mx-auto"/></div>
            <div class="widget-numbers">{{salesPerCustomer | currency}}</div>
            <div class="widget-subheading">{{$t('customers.lang_nav_analytics_turnaroundpercust')}}</div>
            <div :class="'widget-description '+margins.salesPerCustomer.color">
              <span class="pl-1">{{margins.salesPerCustomer.increase}}%</span>
              <font-awesome-icon :icon="margins.salesPerCustomer.icon"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<style scoped>
*{
  z-index: 0;
}
font-awesome-icon{
  color: rgba(0, 0, 0, 0.54) !important;
}
.daily{
  color: #794c8a !important;
}
.widget-numbers{
  font-size: 1.8rem !important;
}
.grid-menu [class*="col-"]{
  border-width: 1px;
}

@media only screen and (max-width: 1200px) {
  .grid-menu [class*="col-"]:nth-child(1){
    border-right: #dee2e6 solid 1px;
  }
  .grid-menu [class*="col-"]:nth-child(3){
    border-right: #dee2e6 solid 1px;
  }
}
</style>
<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {faInboxIn,faEuroSign,faCalendarDay,faSackDollar,faTachometerAltAverage} from '@fortawesome/pro-light-svg-icons';
import {faCoins,faUserFriends,faAngleUp,faAngleDown,faAngleRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
library.add(
    faInboxIn,faEuroSign,faCalendarDay,faSackDollar,faTachometerAltAverage,faAngleUp,faAngleDown,
    faCoins,faUserFriends,faAngleRight

);

import {mapState} from "vuex";

export default {
  name: "SalesDailyOverviewComponent",
  components: {
    FontAwesomeIcon,
  },

  data() {
    return {
      loading: true,
      margins:{
        totalNetto:{increase:0,icon:"angle-right",color:"text-info"},
        totalBrutto:{increase:0,icon:"angle-right",color:"text-info"},
        totalCash:{increase:0,icon:"angle-right",color:"text-info"},
        totalCard:{increase:0,icon:"angle-right",color:"text-info"},
        invoices:{increase:0,icon:"angle-right",color:"text-info"},
        salesPerCustomer:{increase:0,icon:"angle-right",color:"text-info"}
      },
      yesterdaysData:{
        totalNetto:1,
        totalBrutto:1,
        totalCash:1,
        totalCard:1,
        invoices:1,
      },
      data: {
        totalNetto:0,
        totalBrutto:0,
        totalCash:0,
        totalCard:0,
        invoices:0,
      }
    }
  },
  computed: {
    ...mapState([
      'api'
    ]),
    waresPerCustomer() {
      if(this.loading)
        return 0;

      if(this.data === null)
        return 0;

      if(this.data.soldItems === 0 || this.data.invoices === 0)
        return 0;

      return Math.floor((this.data.soldItems / this.data.invoices));
    },
    salesPerCustomer() {
      if(this.loading)
        return 0;

      if(this.data === null)
        return 0;

      if(this.data.totalNetto === 0 || this.data.invoices === 0)
        return 0;

      return (Number(this.data.totalBrutto) / this.data.invoices);
    },
    yesterdaySalesPerCustomer() {

      if(this.loading)
        return 0;
      if(this.yesterdaysData === null)
        return 0;
      if(this.yesterdaysData.totalNetto === 0 || this.yesterdaysData.invoices === 0)
        return 0;
      return Math.floor((Number(this.yesterdaysData.totalBrutto) / this.yesterdaysData.invoices));
    },

  },

  mounted() {
    this.getCashiersReport();

  },

  methods: {
    async getCashiersReport() {
      this.loading = true;

      this.axios.post("get/dashboardOnlineShop/allCashiersDaily/").then((res) => {
        if(res.data.success) {
          this.data = res.data.data;
        }else{

        }
      }).catch(err=>{

      }).finally(()=>{
        this.loading = false;
      })
    },
    setPercent(obj,oldNum,newNum){

      oldNum=Number(oldNum);newNum=Number(newNum);
      let increasePercent =0;
      let decreasePercent =0;

      let increase = newNum - oldNum;
      let decrease = oldNum - newNum;

      increasePercent = (increase / (( oldNum===0)?1:oldNum))*100;
      decreasePercent = (decrease / (( oldNum===0)?1:oldNum))*100;

      if(increasePercent>0){
        obj.icon="angle-up";
        obj.color="text-success";
        obj.increase=Math.abs(increasePercent.toFixed(2));
      }else if(increasePercent<0){
        obj.icon="angle-down";
        obj.color="text-danger";
        obj.increase=Math.abs(decreasePercent.toFixed(2));
      }else{
        obj.icon="angle-right";
        obj.color="text-info";
      }

    }
  }

}
</script>